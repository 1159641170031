module.exports = [{
      plugin: require('/mnt/c/Users/jason/OneDrive/github/poifuture/www.poifuture.com/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('/mnt/c/Users/jason/OneDrive/github/poifuture/www.poifuture.com/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/mnt/c/Users/jason/OneDrive/github/poifuture/www.poifuture.com/src/cms/cms.js"},
    },{
      plugin: require('/mnt/c/Users/jason/OneDrive/github/poifuture/www.poifuture.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
